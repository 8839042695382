import { useContext, useState } from "react";
import { AppContext } from "../services/context";
import axios, { AxiosResponse } from "axios";

interface DataSourceStateIdle {
    status: "idle";
}

interface DataSourceStatePending {
    status: "pending";
}

interface DataSourceStateCompleted<TResponse> {
    status: "completed";
    response: TResponse;
}

interface DataSourceStateError {
    status: "error";
}

export type DataSourceState<TResponse> =
    | DataSourceStateIdle
    | DataSourceStatePending
    | DataSourceStateCompleted<TResponse>
    | DataSourceStateError;

export interface DataSourceConfig<TParams, TData, TResponse> {
    url: string;
    method: "POST" | "GET";
    params?: TParams;
    data?: TData;
    response?: TResponse;
}

type LoadArgs<TConfig> = TConfig extends DataSourceConfig<undefined, undefined, any>
    ? {}
    : TConfig extends DataSourceConfig<infer TParamsOnly, undefined, any>
    ? { params: TParamsOnly }
    : TConfig extends DataSourceConfig<undefined, infer TDataOnly, any>
    ? { data: TDataOnly }
    : TConfig extends DataSourceConfig<infer TParams, infer TData, any>
    ? { params: TParams; data: TData }
    : never;

export type ConfigToParams<TConfig> = TConfig extends DataSourceConfig<infer TData, any, any> ? TData : never;
export type ConfigToData<TConfig> = TConfig extends DataSourceConfig<any, infer TData, any> ? TData : never;
type ConfigToResponse<TConfig> = TConfig extends DataSourceConfig<any, any, infer TResponse> ? TResponse : never;

interface LoadArgsObject<TConfig extends DataSourceConfig<any, any, any>> {
    params?: ConfigToParams<TConfig>;
    data?: ConfigToData<TConfig>;
}

export function useDataSource<TConfig extends DataSourceConfig<any, any, any>>(config: TConfig) {
    const [state, setState] = useState<DataSourceState<ConfigToResponse<TConfig>>>({ status: "idle" });
    const context = useContext(AppContext);

    let isMounted = true;

    async function load(args: LoadArgs<TConfig>, onLoad?: () => void) {
        const argsObject: LoadArgsObject<TConfig> = args;
        setState({ status: "pending" });

        try {
            const url = GEAPP_ADMIN_CONFIG.adminApiUrl + config.url;

            const authHeader = await context.authService.getAuthHeader();

            const response: AxiosResponse<ConfigToResponse<TConfig>> = await axios({
                url: url,
                method: config.method,
                data: argsObject.data || {},
                params: argsObject.params || {},
                headers: { ...authHeader, "Content-Type": "application/json" },
            });

            if (!isMounted) return;
            setState({ status: "completed", response: response.data });

            if (onLoad) onLoad();
        } catch (e) {
            if (!isMounted) return;
            setState({ status: "error" });
            console.log(e);
        }
    }

    function reset() {
        setState({ status: "idle" });
    }

    function cancel() {
        isMounted = false;
    }

    return { state, load, reset, cancel };
}
