import React from "react";

export interface OverlayData {
    show?: boolean;
    title?: string;
    text?: string;
}

const Overlay: React.FC<{ data: OverlayData }> = ({ data: { show, title, text }, children }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="overlay-new">
            {title ? <div className="overlay-new__title">{title}</div> : null}
            {text ? <div className="overlay-new__text">{text}</div> : null}
            <div className="overlay-new__children">{children}</div>
        </div>
    );
};

export default Overlay;
