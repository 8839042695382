import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CatalogueSecondaryNav from "./components/CatalogueSecondaryNav";

import ProductMain from "./views/products/Main";
import CategoryList from "./views/category/List";
import PropertyList from "./views/property/List";
import FileList from "./views/file/Main";
import ProductIE from "./views/productImportExport/Main";

import "./Main.scss";

class CatalogueMain extends Component {
    render() {
        return (
            <div className="area">
                <div className="area-wrapper">
                    <CatalogueSecondaryNav parentProps={this.props} />
                    <Switch>
                        <Route path={`${this.props.match.path}/categories`} component={CategoryList} />
                        <Route path={`${this.props.match.path}/property`} component={PropertyList} />
                        <Route path={`${this.props.match.path}/product`} component={ProductMain} />
                        <Route path={`${this.props.match.path}/files`} component={FileList} />
                        <Route path={`${this.props.match.path}/productIE`} component={ProductIE} />
                    </Switch>
                </div>
            </div>
        );
    }
}

export default CatalogueMain;
